import Slideout from './Modules/Slideout';

Slideout.init();


//-- Blocks
//-- Check if parent of split block container
let blocks = Array.from(document.querySelectorAll('.block-single-split'));
blocks.map(function(block) {
  if (!block.parentElement.classList.contains('container')) {
    return;
  }
  if (block.classList.contains('split-right')) {
    block.querySelector('.container').style.paddingRight = 0;
  } else {
    block.querySelector('.container').style.paddingLeft = 0;
  }
});

//-- Navigation
let mainMenu = document.querySelector('.nav-main > div > ul');
let mainItems = Array.from(mainMenu.querySelectorAll('li'));
let datePlanner = document.querySelector('.date-planner');
let itemSelected = false;

//-- Is current page selected
mainItems.map(function(mainItem) {
  if (mainItem.classList.contains('selected')) {
    let submenu = mainItem.querySelector('ul');
    if (submenu) {
      itemSelected = true;
    }
  }
});

function toggleMenu(status, submenu = null) {
  if (!status) {
    datePlanner.style.transform = "none";
    datePlanner.style.marginBottom = 0;
    return;
  }
  if (status && submenu) {
    datePlanner.style.transform = "translateY(" + submenu.offsetHeight + "px)";
    datePlanner.style.marginBottom = submenu.offsetHeight + "px";

    if (!document.querySelector('.module-media-galleries')) {
      datePlanner.style.marginBottom = (submenu.offsetHeight + 35) + "px";
    }
  }
}

mainItems.map(function(mainItem) {
  let submenu = mainItem.querySelector('ul');

  //-- Set beginner state if selected
  if (mainItem.classList.contains('selected')) {
    toggleMenu(true, submenu);
  }

  mainItem.addEventListener('mouseover', function() {
    toggleMenu(true, submenu);
  });

  mainItem.addEventListener('mouseout', function() {
    if (!itemSelected) {
      toggleMenu(false);
    }
  });
});

let menu = document.querySelector('.nav-main');
let tiltPoint = menu.offsetTop;

window.addEventListener('scroll', function() {
  if (window.pageYOffset > tiltPoint) {
    menu.classList.add('static');
  } else {
    menu.classList.remove('static');
  }
});
